import React, {CSSProperties} from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import {device} from '../../models/devices';

const Container = styled.section`
  padding: 118px 0;
  border-bottom: 1px solid #e6ecfb;
  text-align: center;

  @media screen and ${device.tablet} {
    padding: 80px 24px 94px;
    text-align: left;
  }
`;

const labelStyle: CSSProperties = {
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 500,
  padding: '4px 12px',
  borderRadius: 3,
  display: 'inline-block',
  margin: '0 auto',
  marginBottom: 9,
  letterSpacing: 2
};

const Title = styled.div`
  color: #4f40ab;
  font-size: 48px;
  font-weight: 500;
  max-width: 630px;
  text-align: inherit;
  margin: 5px auto 37px;

  @media screen and ${device.tablet} {
    font-size: 24px;
    margin-top: 7px;
    margin-bottom: 32px;
    max-width: initial;
  }
`;

const Grid = styled.div`
  display: grid;
  height: 600px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-auto-flow: column;
  grid-gap: 30px;
  text-align: left;
  max-width: 1170px;
  margin: 0 auto;
  
  @media screen and ${device.tablet} {
    display: flex;
    flex-direction: column;
    height: auto;
    
    & > div {
      margin-bottom: 24px;
    }
  }
`;

const Text = styled.div<{fontSize: number}>`
  font-weight: 500;
  line-height: 1.5em;
  font-size: ${props => props.fontSize}px;
  
  @media screen and ${device.tablet} {
    font-size: ${props => Math.max(props.fontSize / 1.5, 24)}px;
  }
`;

export const ValuesComponent = (props: {
  label: string;
  color: string;
  backgroundColor: string;
  title: string;
  values: Array<{
    number: number;
    text: string;
    rowspan: number;
    color: string;
    fontSize: number;
  }>
}): JSX.Element => {
  return (
    <Container>
      <h2 style={{
        ...labelStyle,
        color: props.color,
        backgroundColor: props.backgroundColor
      }}><ReactMarkdown>{props.label}</ReactMarkdown></h2>
      <Title><ReactMarkdown>{props.title}</ReactMarkdown></Title>
      <Grid>
        {
          props.values
            .map((value, index) => (
              <div
                key={index}
                style={{
                  gridRowEnd: `span ${value.rowspan}`,
                  boxShadow: '10px 12px 34px 0 rgba(128, 131, 132, 0.18)',
                  borderRadius: 13,
                  padding: 24
                }}
              >
                <span style={{
                  backgroundColor: value.color,
                  color: 'white',
                  padding: '4px 11px',
                  borderRadius: 3,
                  fontSize: 12
                }}>{value.number < 10 ? '0' : ''}{value.number}.</span>
                <Text fontSize={value.fontSize}>
                  <ReactMarkdown>{value.text}</ReactMarkdown>
                </Text>
              </div>
            ))
        }
      </Grid>
    </Container>
  );
};
