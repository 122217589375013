import React, {CSSProperties} from 'react';
import MilestoneBackground from '../../images/milestone-background.svg';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {device} from '../../models/devices';

const Container = styled.section`
  height: 1247px;
  text-align: center;
  padding-top: 120px;

  @media screen and ${device.tablet} {
    text-align: left;
    padding: 48px 24px 0;
    height: auto;
  }
`;

const labelStyle: CSSProperties = {
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 500,
  padding: '4px 12px',
  borderRadius: 3,
  display: 'inline-block',
  margin: '0 auto',
  letterSpacing: 2
};

const Header = styled.div`
  color: #4f40ab;
  font-size: 48px;
  font-weight: 500;
  max-width: 630px;
  text-align: inherit;
  margin: 14px auto 42px;

  @media screen and ${device.tablet} {
    margin-bottom: 0;
    margin-top: 9px;
    font-size: 32px;
    max-width: initial;
  }
`;

const MilestoneContainer = styled.div<{
  color: string;
  index: number;
  length: number;
}>`
  position: absolute;
  display: flex;
  color: ${props => props.color};
  height: ${props => (props.index + (props.index % 2))% 4 === 0 ? 400: 235}px;
  left: ${props => props.index * 135}px;
  top: ${props => props.index % 2 === 0 ? 'initial': '4px'};
  bottom: ${props => props.index % 2 === 0 ? '0': 'initial'};
  flex-direction: ${props => props.index % 2 === 0 ? 'column-reverse': 'column'};

  &:before {
    content: '';
    display: block;
    background: radial-gradient(circle, currentColor 0%, currentColor 22%, white 22%, white 49%, currentColor 49%);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin: -11px -11px -11px -8px;
    transform: translateZ(2px);
  }

  @media screen and ${device.tablet} {
    position: initial;
    height: auto;
    margin-right: 16px;
    
    &:before {
      content: none;
    }
  }
`;

const Timeline = styled.div`
  position: relative;
  margin: 441px auto 0;

  @media screen and ${device.tablet} {
    position: initial;
    display: flex;
    overflow: auto;
    transform: none;
    padding: 40px 24px 80px;
    margin: 0 -24px;
  }
`;

const Line = styled.div<{length: number}>`
  height: 4px;
  background-color: rgb(225, 239, 255);
  width: ${props => (props.length + 1) * 135}px;

  @media screen and ${device.tablet} {
    height: auto;
    background: none;
    width: auto;
  }
`;

const TextContainer = styled.div<{
  color: string;
  flexGrow: CSSProperties['flexGrow'];
  alignItems: CSSProperties['alignItems'];
}>`
  flex: ${props => props.flexGrow};
  border-left: 4px solid;
  border-color: ${props => props.color};
  display: flex;
  align-items: ${props => props.alignItems};
  text-align: left;
  color: black;

  @media screen and ${device.tablet} {
    border: none;
  }
`;

const TextWrapper = styled.div<{color: string}>`
  width: 330px;
  height: 268px;
  background: url(${MilestoneBackground});
  margin: -38px -92px -60px -37px;
  padding: 55px 92px 60px 51px;
  box-sizing: border-box;

  @media screen and ${device.tablet} {
    background: none;
    margin: 0;
    padding: 24px 16px;
    border-radius: 20px;
    box-shadow: 10px 12px 48px rgba(181, 187, 203, 0.205829);
    width: 180px;
    height: auto;
    align-self: stretch;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 100%;
      background: ${props => props.color};
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

const Year = styled.div<{color: string}>`
  color: ${props => props.color};
  font-size: 32px;
  font-weight: bold;
  line-height: 100%;
`;

const MilestoneTitle = styled.div<{fontSize: number}>`
  font-size: ${props => props.fontSize}px;
  font-weight: 600;
  margin-bottom: 2px;

  @media screen and ${device.tablet} {
    font-size: 24px;
    line-height: 1.5;
    word-break: break-word;
  }
`;

const TbcLabel = styled.span`
  font-size: 48px;
  line-height: 100%;
  color: #E0E5EA;
  font-weight: 600;
  position: absolute;
  top: 435px;
  right: -150px;
  text-align: left;

  @media screen and ${device.tablet} {
    position: initial;
    align-self: flex-end;
    padding-right: 24px;
    display: block;
  }
`;

const TimelineWrapper = styled.div`
  width: 1170px;
  margin: 0 auto;
  overflow: auto;
  height: 1000px;
  padding-left: 10px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgb(225, 239, 255);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  @media screen and ${device.tablet} {
    height: auto;
    width: auto;
    overflow: initial;
    padding: 0;
  }
`;

export const Milestones = (props: {
  label: string;
  color: string;
  backgroundColor: string;
  title: string;
  milestones: Array<{
    date: Date;
    title: string;
    color: string;
  }>;
}): JSX.Element => {

  let minDate = props.milestones[0].date;
  let maxDate = props.milestones[0].date;

  props.milestones.forEach(m => {
    if (m.date < minDate) {
      minDate = m.date;
    }
    if (m.date > maxDate) {
      maxDate = m.date;
    }
  });

  return (
    <Container>
      <h2 style={{
        ...labelStyle,
        color: props.color,
        backgroundColor: props.backgroundColor
      }}><ReactMarkdown>{props.label}</ReactMarkdown></h2>
      <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
      <TimelineWrapper>
        <Line length={props.milestones.length}>
          <Timeline>
            {
              props.milestones.map((milestone, index) => (
                <MilestoneContainer
                  key={index}
                  color={milestone.color}
                  length={props.milestones.length}
                  index={index}
                >
                  <div
                    className={'desktop-only'}
                    style={{
                      borderLeft: '4px dashed',
                      borderColor: milestone.color,
                      flex: Math.ceil(Math.random() * 5)
                    }}
                  />
                  <TextContainer
                    flexGrow={Math.ceil(Math.random() * 5)}
                    color={milestone.color}
                    alignItems={index % 2 === 0 ? 'flex-start' : 'flex-end'}
                  >
                    <TextWrapper color={milestone.color}>
                      <Year color={milestone.color}>{milestone.date.getFullYear()}</Year>
                      <MilestoneTitle fontSize={Math.min(738 / milestone.title.length, 18)}>
                        <ReactMarkdown>{milestone.title}</ReactMarkdown>
                      </MilestoneTitle>
                    </TextWrapper>
                  </TextContainer>
                </MilestoneContainer>
              ))
            }
            <TbcLabel>To be <br/> continued...</TbcLabel>
          </Timeline>
        </Line>
      </TimelineWrapper>
    </Container>
  );
};
