import React, {CSSProperties} from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {device} from '../../models/devices';

const Container = styled.section`
  background-size: cover;
  background-position: center;
  padding-top: 87px;
  padding-bottom: 95px;
  
  @media screen and ${device.tablet} {
    padding: 80px 24px;
  }
`;

const Wrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  color: white;
  display: flex;
  align-items: center;

  @media screen and ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const labelStyle: CSSProperties = {
  border: '1px solid',
  padding: '3px 10px',
  borderRadius: 3,
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 500,
  display: 'inline-block',
  backgroundColor: 'rgba(0, 0, 0, 13%)',
  borderColor: 'rgba(255, 255, 255, 42%)',
  marginTop: 0,
  marginBottom: 16,
  letterSpacing: 2
};

const Quote = styled.q<{fontSize: number;}>`
  font-size: ${props => props.fontSize}px;
  font-weight: 600;
  display: block;
  line-height: 1.3;

  &:before, &:after {
    content: none;
  }

  @media screen and ${device.tablet} {
    font-size: 46px;
    line-height: 56px;
  }
`;

export const QuoteComponent = (props: {
  backgroundImg: string;
  quoteImage: string;
  quoteText: string;
  label: string;
  fontSize: number;
}): JSX.Element => {
  return (
    <Container style={{backgroundImage: `url(${props.backgroundImg})`}}>
      <Wrapper>
        <img width={212} style={{marginRight: 55}} src={props.quoteImage} alt=""/>
        <div style={{display: 'inline-block', maxWidth: 800}}>
          <h2 style={labelStyle}><ReactMarkdown>{props.label}</ReactMarkdown></h2>
          <Quote fontSize={props.fontSize}><ReactMarkdown>{props.quoteText}</ReactMarkdown></Quote>
        </div>
      </Wrapper>
    </Container>
  );
};
