import React from 'react';
import Background from '../../images/impact-background.png';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {device} from '../../models/devices';

const Container = styled.section`
  background: url(${Background}) no-repeat bottom center;
  background-size: cover;
  padding-bottom: 220px;

  @media screen and ${device.tablet} {
    padding-bottom: 76px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 164px;
  grid-gap: 10px;
  grid-template-areas:
    'header image'
    'text image';

  @media screen and ${device.tablet} {
    padding: 115px 24px 0;
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.h1`
  font-size: 84px;
  font-weight: bold;
  color: white;
  grid-area: header;
  margin: 0;
  align-self: end;

  @media screen and ${device.tablet} {
    font-size: 49px;
    line-height: 65px;
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.78;
  color: #cfe7fc;
  grid-area: text;

  @media screen and ${device.tablet} {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 32px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 571px;
  grid-area: image;

  @media screen and ${device.tablet} {
    margin: 0 auto;
  }
`;

export const TitleSection = (props: {
  title: string;
  text: string;
  image: string;
  alt: string;
}): JSX.Element => {
  return (
    <Container>
      <Wrapper>
        <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
        <Text><ReactMarkdown>{props.text}</ReactMarkdown></Text>
        <Image src={props.image} alt={props.alt}/>
      </Wrapper>
    </Container>
  );
};
