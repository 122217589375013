import * as React from 'react';
import { graphql } from 'gatsby';
import './styles.scss';
import {MarkdownRemark} from '../models/markdown-remark';
import {BasePageData, BasePageFooter, BasePageHeader} from '../helpers/base-page';
import {TitleSection} from '../components/about/title-section';
import {ValuesComponent} from '../components/about/values';
import {QuoteComponent} from '../components/about/quote';
import {Milestones} from '../components/about/milestones';
import {BackgroundFigure} from '../components/shared/background-figure';
import {RegularSectionModel} from '../models/regular-section-model';
import { RegularSection } from '../components/shared/regular-section';

interface AboutPageData extends BasePageData {
  aboutData: MarkdownRemark<{
    seoTitle: string;
    title: string;
    heroImage: string;
    altText: string;
    description: string;
    subSections: Array<{
      type: 'valuesSection';
      title: string;
      Label: string;
      color: string;
      background: string;
      values: Array<{
        value: {
          number: number;
          text: string;
          rowspan: number;
          color: string;
          fontSize: number;
        }
      }>
    } | {
      type: 'quoteSection';
      Label: string;
      quoteText: string;
      backgroundImg: string;
      quoteImage: string;
      fontSize: number;
    } | {
      type: 'milestonesSection';
      Label: string;
      color: string;
      background: string;
      title: string;
      milestones: Array<{
        milestone: {
          date: string;
          title: string;
          color: string;
        }
      }>
    } | RegularSectionModel>
  }>;
}

const AboutPage = ({data}: {data: AboutPageData}): JSX.Element => {

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.aboutData.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
      />
      <TitleSection
        title={data.aboutData.edges[0].node.frontmatter.title}
        text={data.aboutData.edges[0].node.frontmatter.description}
        image={data.aboutData.edges[0].node.frontmatter.heroImage}
        alt={data.aboutData.edges[0].node.frontmatter.altText}
      />
      {
        data.aboutData.edges[0].node.frontmatter.subSections
          .map((s, index, arr) => {
            switch (s.type) {
              case 'regular': {
                return <RegularSection key={index} {...s.section} style={{
                  ...(index === arr.length - 1 && {borderBottom: 'none'})
                }} />;
              }
              case 'valuesSection': {
                return (
                  <ValuesComponent
                    key={index}
                    label={s.Label}
                    color={s.color}
                    backgroundColor={s.background}
                    title={s.title}
                    values={s.values.map(v => v.value)}
                  />
                );
              }
              case 'quoteSection': {
                return (
                  <QuoteComponent
                    key={index}
                    label={s.Label}
                    backgroundImg={s.backgroundImg}
                    quoteImage={s.quoteImage}
                    quoteText={s.quoteText}
                    fontSize={s.fontSize}
                  />
                );
              }
              case 'milestonesSection': {
                return (
                  <div key={index} style={{position: 'relative', maxWidth: 1170, margin: '0 auto'}}>
                    <div className={'desktop-only'}>
                      <BackgroundFigure
                        type={'dots'}
                        color={''}
                        size={{width: 800, height: 730}}
                        position={{top: 265}}
                      />
                    </div>
                    <Milestones
                      label={s.Label}
                      color={s.color}
                      backgroundColor={s.background}
                      title={s.title}
                      milestones={
                        s.milestones.map(m => ({
                          ...m.milestone,
                          date: new Date(m.milestone.date)
                        }))
                      }
                    />
                  </div>
                );
              }
              default:
                return '';
            }
          })
      }
      <div className={'desktop-only'}>
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 76, height: 203}}
          position={{left: 0, top: 1063}}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#ffc02a'}
          size={{width: 36, height: 73}}
          position={{right: 0, top: 791}}
          style={{
            zIndex: 0,
            borderRadius: 36,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#67dffa'}
          size={{width: 36, height: 73}}
          position={{right: 0, top: 1715}}
          style={{
            zIndex: 0,
            borderRadius: 36,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#00e0dd'}
          size={{width: 22, height: 22}}
          position={{top: 1557, left: 52}}
        />
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 76, height: 203}}
          position={{right: 0, top: 1935}}
        />
        <div style={{
          transform: 'rotate(180deg)',
          position: 'absolute',
          left: 21,
          top: 2050
        }}>
          <BackgroundFigure
            type={'triangle'}
            color={'#0089ff'}
            size={{width: 40, height: 40}}
          />
        </div>
      </div>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  {
    ...BaseData
    aboutData: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/\\/about\\/about.md$/"      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            heroImage
            altText
            description
            subSections {
              type
              title
              Label
              color
              background
              quoteText
              backgroundImg
              quoteImage
              fontSize
              section {
                alignment
                label
                color
                background
                title
                image
                altText
                subText
              }
              milestones {
                milestone {
                  date
                  title
                  color
                }
              }
              values {
                value {
                  number
                  text
                  rowspan
                  color
                  fontSize
                }
              }
            }
          }
        }
      }
    }
  }
`;
